import { POST_CREATED } from "../types";

const initialState = {
  postCreated: false,
};

const community = (state = initialState, action) => {
  switch (action.type) {
    case POST_CREATED:
      return { ...state, postCreated: true };
    case `${POST_CREATED}_RESET`:
      return { ...state, postCreated: false };
    default:
      return state;
  }
};

export default community;
