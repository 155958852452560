import { POST_CREATED } from "../types";

// Action to indicate a new post has been created
export const postCreated = () => ({
  type: POST_CREATED,
});

// Action to reset the post created status
export const resetPostCreated = () => ({
  type: `${POST_CREATED}_RESET`,
});
